<template>
  <v-expansion-panel class="custom-expan-panel">
    <v-expansion-panel-header style="font-size: 1em;">
      Почасовая работа 
      <span v-if="costHourlyWorks" class="pl-1">{{ costHourlyWorks }}</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="custom-expan-panel_cont">
      <v-divider />
      <div
        v-if="hourlyWorks.length === 0"
        class="pa-3 pl-6"
      >
        Нет работы
      </div>
      <v-simple-table v-if="hourlyWorks.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дата</th>
              <th class="text-left">Часы</th>
              <th class="text-left">Что сделал</th>
              <th class="text-left">Итого</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="task in hourlyWorks"
              :key="task.id"
              class="tableItemList"
              :set="ifHPWM = $ifHourPayWorkMasters(task, user)"
            >
              <td style="min-width: 85px;">{{ $formatDate(task.date.toDate()) }}</td>
              <td style="min-width: 50px;">
                <span v-if="task.hourPayWorkMasters && user.role != 'prodDir'">
                  {{ ifHPWM.allHour }}ч.
                </span>
                <span v-else>
                  {{ task.numberOfHours }}ч.
                </span>
              </td>
              <td style="max-width: 110px;">{{ $sliceStr($getStrTasks(task.tasks), 20) }}</td>
              <td>
                <span v-if="task.hourPayWorkMasters && user.role != 'prodDir'">
                  {{ ifHPWM.cost }}р.
                </span>
                <span v-else-if="task.numberOfHours && task.rate">
                  {{ Number(task.numberOfHours) * task.rate }}р.
                </span>
                <span v-else>Ничего</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  data: () => ({}),
  methods: {},
  computed: {
    hourlyWorks() {
      return this.$store.getters['hourlyWorks/getTasks']
    },
    costHourlyWorks() {
      if (!this.hourlyWorks || this.hourlyWorks.length === 0) {
        return false
      }
      let cost = 0
      this.hourlyWorks.map(work => {
        if (work.hourPayWorkMasters && this.user.role != 'prodDir') {
          let ifHPWM = this.$ifHourPayWorkMasters(work, this.user)
          cost = Number(cost) + ifHPWM.cost
        } else if (work.numberOfHours) {
          let rate = 0
          if (work.rate) {
            rate = Number(work.rate)
          }
          cost = Number(cost) + (Number(work.numberOfHours) * rate)
        }
      })

      return `${cost}р.`
    },
    uid() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser.uid
      }
      return this.$route.params.uid
    },
    user() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser
      }
      return this.$store.getters['users/getUser'](this.uid)
    },
    currentUser() {
      return this.$store.getters['user/getUser']
    }
  }
}
</script>