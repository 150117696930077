<template>
  <v-expansion-panel class="custom-expan-panel">
    <v-expansion-panel-header 
      :hide-actions="currentUser.role === 'admin'"
      style="font-size: 1em;"
      class="d-flex justify-space-between"
      :class="{
        'py-0': currentUser.role === 'admin',
        'pr-5': currentUser.role === 'admin'
      }"
    >
      <span>
        Выплаты 
        <span v-if="summPrepayments" class="pl-1">{{ summPrepayments }}</span>
      </span>
      <v-btn max-width="38" v-if="currentUser.role === 'admin'" icon @click.stop="createPrepayment()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="custom-expan-panel_cont">
      <v-divider />
      <div 
        v-if="prepayments.length === 0"
        class="pa-3 pl-6"
      >
        Нет выплат
      </div>
      <v-simple-table v-if="prepayments.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дата</th>
              <th class="text-left">Сумма</th>
              <th v-if="currentUser.role === 'admin'" class="text-center">Действия</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="prepayment in prepayments"
              :key="prepayment.id"
              class="tableItemList"
            >
              <td style="min-width: 80px;">{{ $formatDate(prepayment.date.toDate()) }}</td>
              <td style="min-width: 125px;">{{ prepayment.cost }}р.</td>
              <td v-if="currentUser.role === 'admin'" class="text-center">
                <v-btn @click.stop="deleteItem(prepayment)" icon color="#bf4037">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { usersCollection } from '@/plugins/firebase'
import Finance from '@/components/Modals/Finance.vue'
import Confirm from  '@/components/Modals/Confirm'

export default {
  components: {},
  props: {
    currentUser: {
      type: Object,
      default: {}
    },
    user: {
      type: Object,
      default: {}
    }
  },
  data: () => ({}),
  methods: {
    async deleteItem(data) {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление выплаты`,
          text: `Действительно хотите удалить выплату за ${this.$formatDate(data.date.toDate())}?`
        }
      })

      if (res) {
        await this.$store.dispatch('prepayments/deletePrepayments', data)
      }
    },
    async createPrepayment() {
      let summ = await this.$showModal({
        component: Finance,
        isPersistent: false,
        props: {
          title: 'Выдать выплату',
          event: 'Выдать'
        }
      })

      if (summ) {
        this.$store.dispatch('prepayments/createPrepayments', {
          cost: summ,
          user: usersCollection.doc(this.user.uid)
        })
      }
    }
  },
  computed: {
    prepayments() {
      return this.$store.getters['prepayments/getPrepayments']
    },
    summPrepayments() {
      if (!this.prepayments || this.prepayments.length === 0) {
        return false
      }
      let price = 0
      this.prepayments.map(prepayment => {
        if (prepayment && prepayment.cost) {
          price = price + Number(prepayment.cost)
        }
      })
      return `${price}р.`
    }
  }
}
</script>