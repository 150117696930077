<template>
  <div>
    <Header 
      :user="user"
      :currentUser="currentUser"
    />

    <v-tabs
      v-model="tab"
      fixed-tabs
      color="#e0a677"
      background-color="#626b70"
      class="mobTasksPage_tabs"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        v-for="item in tabs"
        :key="item.key"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="background-color: #424242;">
      <v-tab-item
        v-for="item in tabs"
        :key="item.key"
      >
        <TabWork 
          v-if="item.key === 'work'"
          :user="user"
          :currentUser="currentUser"
        /> 
        <TabFinance
          v-if="item.key === 'finance'"
          :user="user"
          :currentUser="currentUser"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Header from './Components/Header/Index.vue'

import TabWork from './Components/MobTabs/Work/Index.vue'
import TabFinance from './Components/MobTabs/Finance/Index.vue'

export default {
  name: 'User',
  components: {
    Header,
    TabWork,
    TabFinance
  },
  data: () => ({
    tab: 0,
    tabs: [
      {
        key: 'work',
        text: 'Работа'
      },
      {
        key: 'finance',
        text: 'Финансы'
      }
    ]
  }),
  methods: {},
  computed: {
    uid() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser.uid
      }
      return this.$route.params.uid
    },
    user() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser
      }
      return this.$store.getters['users/getUser'](this.uid)
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    currentUser() {
      return this.$store.getters['user/getUser']
    }
  }
}
</script>

<style lang="scss">
.costRed {
  color: red;
}
.user {
  &_mob {}

  &_info {
    display: flex;
    justify-content: space-between;

    &_right {
      display: flex;
      align-items: center;

      &_credit {
        &_text {
          font-size: 1.4em;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 0.57em;
        }

        &_btn {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  &_selectDataMonth {
    .v-text-field__details {
      display: none;
    }
  }
}
</style>
