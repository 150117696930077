<template>
  <v-expansion-panel class="custom-expan-panel">
    <v-expansion-panel-header style="font-size: 1em;">
      Задания
      <span v-if="costTasks" class="pl-1">{{ costTasks }}</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="custom-expan-panel_cont">
      <v-divider />
      <div
        v-if="tasks.length === 0"
        class="pa-3 pl-6"
      >
        Нет заданий
      </div>
      <v-simple-table v-if="tasks.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дата</th>
              <th class="text-left">Клиент</th>
              <th class="text-left">Стоимость</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="task in tasks"
              :key="task.id"
              class="tableItemList"
            >
              <td style="min-width: 85px;">{{ $formatDate(task.date.toDate()) }}</td>
              <td style="min-width: 125px;">{{ task.client.name }}</td>
              <td>
                {{ 
                  task.montagersInfo[user.uid] 
                    ? task.montagersInfo[user.uid].costWork
                    ? `${task.montagersInfo[user.uid].costWork}р.` 
                    : 0 
                    : 0 
                }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider v-if="tasks.length > 0" />
      <v-row v-if="tasks.length > 0" class="ma-0">
        <v-col cols="12" class="d-flex align-center justify-center mt-1">
          <v-btn 
            icon
            large
            @click="tasksPage > 1 ? pagination('prev') : null"
            :disabled="tasksPage === 1"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span style="font-size: 0.85em;">{{ tasksPage }} стр.</span>
          <v-btn 
            icon
            large
            @click="listTasksPage.length > 0 && tasksPage < tasksLastPage ? pagination('next') : null"
            :disabled="tasks.length < 10 || tasksPage === tasksLastPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  methods: {
    pagination(direction) {
      if (direction === 'next' && this.tasksPage != this.tasksLastPage) {
        this.$store.commit('manufactory/setTasksForMasterPaginationPage', this.tasksPage + 1)
      } else if (direction === 'prev' && this.montagesPage != 1) {
        this.$store.commit('manufactory/setTasksForMasterPaginationPage', this.tasksPage - 1)
      }
    }
  },
  computed: {
    tasksLastPage() {
      return this.$store.getters['manufactory/getTasksForMasterLastPage']
    },
    tasksPage() {
      return this.$store.getters['manufactory/getTasksForMasterPage']
    },
    listTasksPage() {
      return this.$store.getters['manufactory/getListTasksPageForMaster']
    },
    tasks() {
      return this.$store.getters['manufactory/getTasksForMaster']
    },
    costTasks() {
      if (!this.tasks || this.tasks.length === 0) {
        return false
      }
      let price = 0
      this.tasks.map(task => {
        if (task.montagersInfo[this.user.uid] && task.montagersInfo[this.user.uid].costWork) {
          price = price + Number(task.montagersInfo[this.user.uid].costWork)
        }
      })
      return `${price}р.`
    },
    uid() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser.uid
      }
      return this.$route.params.uid
    },
    user() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser
      }
      return this.$store.getters['users/getUser'](this.uid)
    },
    currentUser() {
      return this.$store.getters['user/getUser']
    }
  }
}
</script>